import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react'

const plans = [
  {
    name: 'Startup',
    ram: '12GB',
    cpus: '6 CPUs',
    disk: '160 GB SSD disk',
  },
  {
    name: 'Business',
    ram: '16GB',
    cpus: '8 CPUs',
    disk: '512 GB SSD disk',
  },
  {
    name: 'Enterprise',
    ram: '32GB',
    cpus: '12 CPUs',
    disk: '1024 GB SSD disk',
  },
]
const collapsedIcon = ()=>{
  return alert('dds')
}
function PaymentOption(props) {
  const [payments, setPayments] = useState([])
  const [referencePayment, setPaymentsReference] = useState([])
  const [selected, setSelected] = useState(null)
  const [collapse, setCollapsed] = useState(true)
  
  React.useEffect(() => {
    var initial = props.details
    var list  =  props.details.filter(item => item.itemName === "Bank Deposit")[0].items
    var combined =    [...initial, ...list]

    if (props.isItinerary) {
      setPayments(combined.filter(item => item.itemName ===props.selectedPayment)) 
      setPaymentsReference(combined.filter(item => item.itemName ===props.selectedPayment))
    }else {
      setPayments(combined.filter(item => item.itemName !== "Bank Deposit"  && item.status === true)) 
      setPaymentsReference(combined.filter(item => item.itemName !== "Bank Deposit"  && item.status === true))
    }
    
    setSelected(combined.filter(item => item.itemName !== "Bank Deposit")[0])
  }, [])


  // React.useEffect(() => {
  //   setPaymentsReference(props.setInitial)
    
  // },[props.setInitial])

  function renderBank(e){
      var items = [<></>]
      console.log(e)
      {e.map((plan) => (
        items.push(
            <RadioGroup.Option
            key={plan.itemName}
            value={plan}
            className={({ active, checked }) =>
              `${
                active
                  ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                  : ''
              }
              ${
                checked ? 'bg-sky-900 bg-opacity-75 text-white w-full' : 'w-full bg-white'
              }
                relative rounded-lg px-5 py-4 cursor-pointer flex focus:outline-none`
            }
          >

            {({ active, checked }) => (
              <>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-light  text-xs ${
                          checked ? 'text-white' : 'text-gray-900'
                        }`}
                      >
                        {plan.itemName} 
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={`inline ${
                          checked ? 'text-sky-100' : 'text-gray-500'
                        }`}
                      >
                 <img
          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
          src={plan.icon_url}
          alt=""
        />
        
                        <span aria-hidden="true">&middot;</span>{' '}
                        <span>{plan.itemDetails}</span>
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && (
                    <div className="flex-shrink-0 text-white">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
          )
      )
      )} 
      return  items
  }
  
  function renderMobileAppContent(){
    return (

      <div
      class="absolute inset-0 flex flex-col items-start justify-end "
    >
      {/* <h3 class="text-xl font-medium text-white">Paymongo Ready</h3> */}
      <div>
        < a href={props.details.storeOptions != null  ? props.details.storeOptions.appLinks.apple : ''}>
      <span
        class="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide lg:mt-8 text-white"
      >
  Download iOS
      </span>
      </a>
      < a href={props.details.storeOptions != null  ? props.details.storeOptions.appLinks.android : ''}>
      <span
        class="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
      >
       Download Android
      </span>
      </a>
      </div>
     </div>
    )
  }
  function getSelectedPayment(e){
    setSelected(e)
    props.selectePayment(e)
    console.log(e)
  }

  const collapsed = (type)=>{
    console.log(selected.key)
    if (collapse) {
      let list = referencePayment.filter(item => item.key === selected.key)
      // console.log(list)
      setPayments(list) 
    } else {
      setPayments(referencePayment) 
    }
    
    setCollapsed(!collapse)

  }

  return (
    <div className="w-full">
          <div className="flex justify-between mb-10 content-center">
              <h3 className="   text-black text-xs font-bold   leading-5"> Payment Method - <span class="text-blue-800">{selected != null ? selected.itemName : ''}</span></h3>
              {/* <button  onClick={collapsed} class="text-xs bg-gray-200 rounded-full mt-4" ><div className='m-2 mt-2 mb-2'>{collapse === false?'Show more' : 'Show less'}</div> </button> */}
     
             
              {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
               </svg> */}
               {/* <div className={` rotate-180  ${collapse ?  'rotate-180 ' :'rotate-90 ' }` }>
               <svg class="svg-icon" onClick={collapsed} className={`h-5 w-5 rotate-180 ` }   viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 96C282.25 96 96 282.25 96 512s186.25 416 416 416 416-186.25 416-416S741.75 96 512 96z m0 544l-192-192h384l-192 192z"  /></svg>
               </div> */}
               {/* <svg class="svg-icon"  className="h-5 w-5"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M295.2 421.4c-15 15-15 39.6 0 54.6l191.4 190.8c14.6 14.6 38.2 15 53.2 1.2l188.6-188c7.6-7.6 11.4-17.4 11.4-27.4 0-9.8-3.8-19.8-11.2-27.2-15-15-39.4-15.2-54.6 0l-162 159.6-162.2-163.8c-15-15-39.4-15-54.6 0.2z"  /><path d="M96 512c0 229.8 186.2 416 416 416s416-186.2 416-416S741.8 96 512 96 96 282.2 96 512z m664.8-248.8C827.4 329.6 864 418 864 512s-36.6 182.4-103.2 248.8C694.4 827.4 606 864 512 864s-182.4-36.6-248.8-103.2C196.6 694.4 160 606 160 512s36.6-182.4 103.2-248.8C329.6 196.6 418 160 512 160s182.4 36.6 248.8 103.2z"  /></svg> */}
          </div>

          
      <div className="w-full ">
        
        <RadioGroup value={selected} onChange={(e)=>getSelectedPayment(e)}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {payments.map((plan) =>  
        <RadioGroup.Option
          key={plan.itemName}
          value={plan}
          className={({ active, checked }) =>
            `${
              active
                ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                : ''
            }
            ${
              checked ? 'bg-sky-900 bg-opacity-75 text-white border border-gray-500 ease-in-out duration-300 ' : 'bg-white'
            }
              relative rounded-lg hover:shadow-md px-5 cursor-pointer flex focus:outline-none ease-in-out duration-300`
          }
        >
  
              
          {({ active, checked }) => (
            <>
              <div className="flex items-center m-5 justify-between w-full">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className={`font-medium  ${
                        checked ? 'text-gray-900 text-xs' : 'text-gray-400 text-xs'
                      }`}
                    >
                      {/* {plan.itemName} */}
                      
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={`inline ${
                        checked ? 'text-gray-900 ' : 'text-gray-400 text-sm'
                      }`}
                    >
                             <img
        className="inline-block h-8 w-8 rounded-full ring-2 mr-2 ring-white"
        src={plan.icon_url}
        alt=""
      />
      {/* {checked ?  <div className="absolute top-2  right-0">
       <button
               type="button"
               className="inline-flex  w-100 justify-center px-4 py-2 text-xs font-medium   text-black bg-gray-100 border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
           
             >Make Default
             <a href="/" >  <div className="flex-shrink-0 text-black">
                   <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
</svg>
                  </div></a>
               </button>
               </div> :null } */}
     
                      <span aria-hidden="true" className="mr-2">&middot;</span>{' '}
                      <span>{plan.itemDetails}</span>
                    </RadioGroup.Description>
                  </div>
                  
                </div>

                {/* {checked ? (
                  <div className="flex-shrink-0 text-black">
                   <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
</svg>
                  </div>
                ) : null} */}
              </div>
              
            </>
            
          )}
   </RadioGroup.Option>
       
            
            )}
          </div>



        </RadioGroup>
        {/* <div className={` rotate-180  ${collapsed ?  'rotate-180 ' :'rotate-90 ' }` }>
               <svg class="svg-icon" onClick={collapsed} className={`h-5 w-5 rotate-180 ` }   viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 96C282.25 96 96 282.25 96 512s186.25 416 416 416 416-186.25 416-416S741.75 96 512 96z m0 544l-192-192h384l-192 192z"  /></svg>
               </div> */}



<button  onClick={collapsed} class="text-xs ml-2 bg-gray-200 rounded-full mt-4 mb-4" >
  
  <div className='m-2 mt-2 mb-2 flex  '>
  <img src="https://www.svgrepo.com/show/9862/payment-methods.svg" className="h-4 w-auto mr-2 "/>
            {!collapse  ?'Show more ' : 'Show less'}</div> </button>


       {/* <button  onClick={collapsed} class="text-xs bg-gray-200 rounded-full mt-4" ><div className='m-2 mt-2 mb-2'>{collapse ? 'Show less':'Show more' }</div> </button> */}
      </div>
      {/* { selected != null ? selected.key === "xx3" ?renderMobileAppContent() : '' : ''} */}
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}



export  {PaymentOption,collapsedIcon}