import { Fragment, useState ,useEffect} from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import Footer from './component/Footer'
import LandingHeader from './component/LandingHeader'
import { axios } from './Network'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
var id =   process.env.REACT_APP_STORE_ID
const headerCSS = "text-sm font-bold text-slate-700 mb-5 mt-10"
const MyLoader = (props) => (
  <ContentLoader viewBox="0 0 400 31" height={200} width={'auto'} {...props}>
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
        <circle cx="388" cy="12" r="12" />
        <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
      </ContentLoader>
  )
export default function Example(props) {
  const [open, setOpen] = useState(true) 
  let [isOpen, setIsOpen] = useState(true)
  const [enabled, setEnabled] = useState(false)
  var product = props.item
  let [didLoad, didLoadStore] = useState(false)
  let [store, setStoreDetails] = useState({store:'',storeOptions:null})

  useEffect(() => {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
    .post("/details/Store", parameter)
    .then((response) => {
      setStoreDetails(response.data.results[0])
      didLoadStore(true)
      
    })
    .catch(function (error) {
      toast('Something went wrong, please try again')
    });
  }, [])
  return (
    <>
    
<div class="m-10">
<img
                      className="h-20   lg:text-center-1 w-auto sm:h-30 sticky margin: 20rem rounded-lg hover:shadow-lg my-20"
                      src={store === null  ? '' : store.storeLogo }
                    />
<h6 className="uppercase sm:text-center lg:text-left-1 sticky margin: 20rem my-20 text-4xl tracking-tight content-center font-extralight text-gray-900 sm:text-2xl md:text-6xl mb-90">   

Terms and Condition
</h6>  
        <div></div> 
  <p className="mt-3 text-base text-gray-500 mb-2"> 
These are the terms and conditions upon which The Flower Luxe Cebu make this website, www.theflowerluxecebu.com, and any of our services available to you.
</p>



<h6 className={headerCSS}>   

PRODUCTS
</h6>  


<ul class="list-disc ml-5"> 
<li> <p className="mt-3 text-base text-gray-500 mb-2">All products as depicted on this website are available in Cebu, Philippines. </p></li> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> Certain flower and plant products may be delivered in bud to ensure longer life. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> All products are subject to availability.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> In the event of any supply difficulties, we reserve the right to substitute any of the flowers or packaging materials with a product of equivalent or greater value which we will notify client before we deliver. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> We try to keep the representation of the colors of our products as accurate as possible. However, due to differences between display monitors along with the natural variations of our products, the colors you see on your screen are intended only as a guide. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Some flowers may have natural discolorations on their petals but they are still guaranteed as fresh.</p></li>
</ul>



<h6 className={headerCSS}>   

PRICES
</h6>  




<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> Please note that from time to time, product prices on www.theflowerluxecebu.com may vary to those offered in The Flower Luxe Cebu store or via our contact numbers and social media channels. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Prices are subjected to change without notice especially during peak seasons.</p></li>
</ul>





<h6 className={headerCSS}>   

ORDERS / PAYMENT
</h6>

<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> You will be given clear instructions to navigate the order process and will be required to provide us with your complete personal details, including accurate delivery address, together with those of the intended recipient and all necessary payment details. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> We accept payment via credit cards, debit cards, bank deposit, bank transfer, and Paypal – Paypal payment with 5% additional charge for Paypal’s service fee. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Submitting your order is subject to our confirmation</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> You will need to send us a copy of the proof of payment before we confirm your order.</p></li>
 
</ul>




<h6 className={headerCSS}>   

REFUNDS
</h6>

<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> If the recipient has received a damaged or defective arrangements, we guarantee to replace it as long as you notify us within 3 hours upon delivery and furnish us with a picture of the item. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Discolorations on the tip of petals of some flowers are not considered as damaged. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Late deliveries do not qualify for full refund if the arrangement was accepted by the client or receiver. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> If for whatever reason The Flower Luxe Cebu is unable to deliver your order, we will refund the full amount. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Arrangements must be returned in its original condition and packaging within 24 hours to guarantee a full refund. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> In the event that we are unable to supply all your full order, we shall notify you as soon as possible and reimburse your payment in full no later than 7 working days after the intended delivery/collection date. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Once a refund request is approved by our management, the refunded amount will be returned through client’s bank account. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Due to the perishable nature of all flowers, complaints and refunds will be dealt with on a case to case basis at our management’s discretion. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Items with no defective elements do not qualify for a refund or change of order for the reason of “client’s change of mind”</p></li>
</ul>


<h6 className={headerCSS}>   

DELIVERY
</h6>



<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> Standard Delivery is the next day (unless stated), will be applicable within Cebu City. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Same day delivery will be subject to product availability, or if client agrees with what The Flower Luxe Cebu offers as an alternative. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> We cannot guarantee an exact delivery time most especially during peak seasons, but rest assured your arrangements will be delivered on the date of your order.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Deliveries of all products bought from The Flower Luxe Cebu are personally delivered by our own delivery vehicles and drivers. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> To avoid problems or delays with delivery, please ensure that you have included the full address details, including accurate landmarks of the intended recipient and telephone number, together with your contact number so that we can notify you in the event that any delivery problems are encountered. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Please note that if the receiver at the stated address is unavailable, The Flower Luxe Cebu will not be at fault.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> We reserve the right to charge for the re-delivery fee if needed. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> The Flower Luxe Cebu will not take responsibility for late delivery of any flower arrangement due to incomplete or incorrect delivery addresses, as well as any other causes beyond our reasonable control (e.g traffic, accidents or adverse weather conditions). </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> The Flower Luxe Cebu will strictly follow the stated delivery address on the Delivery Details the client stated on the website.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Addresses put on the notes will be disregarded</p></li>


</ul>


<h6 className={headerCSS}>   

CANCELLATION/AMENDMENTS
</h6>


<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> If for any reason, you wish to change your order you can do so by emailing theflowerluxe.cebu@gmail.com.  </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2">  Please give 24 hours’ notice before the requested delivery date. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> No cancellation of fully paid orders. No cash refund is permitted</p></li>
</ul>




<h6 className={headerCSS}>   

THE FLOWER LUXE CEBU CUSTOMER CONTACT CENTRE
</h6>




<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> In the event that you are not satisfied with The Flower Luxe Cebu’s products and services, any complaints should be addressed in the first instance to by email: theflowerluxe.cebu@gmail.com or by Phone: 0917 826 5069 </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Our Customer Service Centre is available from 9:00am to 5:00pm Monday to Saturday. </p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Because of the perishable nature of most products and in order to assist us in resolving any complaints quickly and to our mutual satisfaction, we advise you to make any complaint within 1 working day of the date of delivery or intended delivery of your purchase.</p></li>


</ul>



<h6 className={headerCSS}>   

INTELLECTUAL PROPERTY
</h6>



<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> All products, photos, and rights, in this website are owned by THE FLOWER LUXE CEBU.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Any use of this website or its contents, including photos and products storing it or them in whole or part, is prohibited without the permission of THE FLOWER LUXE CEBU.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> You should not modify, distribute or repost something from this website for any reason.</p></li>

</ul>



<h6 className={headerCSS}>   

PRODUCTS
</h6>

<ul class="list-disc ml-5"> 
<li><p className="mt-3 text-base text-gray-500 mb-2"> All products as depicted on this website are available in Cebu, Philippines.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> All products are subject to availability.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> In the event of any supply difficulties, we reserve the right to substitute any of the flowers or packaging materials with a product of equivalent or greater value.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> We try to keep the representation of the colors of our products as accurate as possible. However, due to differences between display monitors along with the natural variations of our products, the colors you see on your screen are intended only as a guide.</p></li>
<li><p className="mt-3 text-base text-gray-500 mb-2"> Some flowers may have natural discolorations on their petals but they are still guaranteed as fresh</p></li>
</ul>





<p className="mt-20 text-base text-gray-500 mb-2">  
Questions on our Terms of Service may be emailed to theflowerluxe.cebu@gmail.com
</p>

















  </div>

  {store.storeOptions != null ? <Footer  details={store} />:<MyLoader/>} 
    </>
)

}