
export let firstItem =   {
    "_id": "632b0df0598ce703b2d6d417",
    "subtitle": "China Roses, Carnations, Lisianthus, Stargazer, Misty, Parvi ",
    "title": "Bouquet Amelia ",
    "price": "6650",
    "status":true,
    "imgUrl": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1663765957124-2A485680-4845-4468-AF2A-8AFE76146649.jpg",
    "storeOwner": "60b1c9a9a001ef1e463d52c2",
    "type": "Flux-Bouquet",
    "display": true,
    "imageDetails": {
        "host": "https://localflowershop.sgp1.digitaloceanspaces.com",
        "filename": "1663765957124-2A485680-4845-4468-AF2A-8AFE76146649.jpg",
        "link": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1663765957124-2A485680-4845-4468-AF2A-8AFE76146649.jpg"
    },
    "displayType": "Regular",
    "priceSurgesDates": []
}

export  let secondtItem = {
    "_id": "60bb30e75d892db7a409197b",
    "subtitle": "Kimberly with Dried Eucalyptus",
    "title": "Kimberly",
    "price": "5250",
    "status":true,
    "imgUrl": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1622880483501-Y9.jpg",
    "storeOwner": "60b1c9a9a001ef1e463d52c2",
    "type": "Flux-Bouquet",
    "display": true,
    "imageDetails": {
        "host": "https://nidz.fra1.digitaloceanspaces.com",
        "filename": "1622880483501-Y9.jpg",
        "link": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1622880483501-Y9.jpg"
    },
    "objectId": "60bb30e75d892db7a409197b",
    "quantity": 1,
    "status": true,
    "forAndroid": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1624861874963-Y9.jpg",
    "subCategory": "YouAreSpecial",
    "priceSurgesDates": [],
    "unAvailableDates": [],
    "displayType": "Occasion"
}

export  let thirdItem = {
    "_id": "60bc6f6b5d892db7a40919ec",
    "subtitle": "Roses, Carnations, Misty Blues - Big Flowerbox",
    "title": "Mix and Match - 4",
    "price": "4500",
    "status":true,
    "imgUrl": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1622962023075-B4.jpg",
    "storeOwner": "60b1c9a9a001ef1e463d52c2",
    "type": "Lux-Arrangements",
    "display": true,
    "imageDetails": {
        "host": "https://nidz.fra1.digitaloceanspaces.com",
        "filename": "1622962023075-B4.jpg",
        "link": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1622962023075-B4.jpg"
    },
    "objectId": "60bc6f6b5d892db7a40919ec",
    "quantity": 1,
    "status": true,
    "forAndroid": "https://localflowershop.sgp1.digitaloceanspaces.com/product/1624863386409-B5.jpg",
    "displayType": "Occasion"
}