import React, { Fragment, useState, useEffect,useMemo ,useRef} from 'react'

import { axios ,productStats,axiosOTP} from '../Network'
const OTPContent = (props)=>{
  let [isLoading, setLoading] = useState(false)
    let [store, setIsOpen] = useState(props.store)
    let [codeOne, setCodeOne] = useState('')
    let [codeTwo, setCodeTwo] = useState('')
    let [codeThree, setCodeThree] = useState('')
    let [codeFor, setCodeFour] = useState('')
    let [codeFive, setCodeFive] = useState('')
    let [codeSix, setCodeSix] = useState('')
    let [isAllowButton, setButtonAllow] = useState(false)
   const currentOneFocus = useRef(null);
   const currentTwoFocus = useRef(null);
   const currentThreeFocus = useRef(null);
   const currentFourFocus = useRef(null);
   const currentFiveFocus = useRef(null);
   const currentSixFocus = useRef(null);
   const allowButton = useRef(true);

   const buttonFocus = useRef(null);




    const setDynamicFocus = (event)=>{
      console.log(event.target.value)
      let value = event.target.value

        switch (event.target.id) {
          case '1':
        
          setCodeOne(value)
    
          currentTwoFocus.current.focus()
          break;
          case '2':
          setCodeTwo(value)

          currentThreeFocus.current.focus()
         
          break;
          case '3':

          setCodeThree(value)

          currentFourFocus.current.focus()

          break;
          case '4':

          setCodeFour(value)

          currentFiveFocus.current.focus()

          break;
          case '5':

          setCodeFive(value)

          currentSixFocus.current.focus()

        
          break;
          case '6':
          setCodeSix(value)
          
      setLoading(true)
          // alert('NICE')

      console.log('setter')
          break;
          default:
        }
        // validateValues().then(status =>{
        //   status ? validateOTP(null): null
        // })
    }



    async  function   validateValues (event){
   
try {
  
  console.log(codeOne,codeTwo,codeThree,codeFor,codeSix,codeFive)
  if(codeFive === ''  || codeSix  === ''  ||  codeFor  === ''  ||   codeThree  === ''  ||  codeTwo  === ''  ||  codeOne  === '' ) {
   setButtonAllow(false)
   allowButton.current = true
   return false
  } else {
   setButtonAllow(true)
   allowButton.current = false
   setLoading(true)
   return true
  }
} catch (error) {
  return false
}

    }


    const resendOTP = ()=>{
      setLoading(true)
      let payload =  {"storeOwner":"FLC","emailReceiver":"winx.raffin@gmail.com"}

      axiosOTP
      .post("/storeOTP", payload)
      .then((response) => {
        
        setLoading(false)
      }) .catch(function (error) {
        setLoading(false)
        // toast('Something went wrong, please try again')
      });
    }

    const validateOTP = ()=>{
      let parameter = {
        id: "6526a9bd12f32bf4b0f37a1b",
        equalTo: true, storeOwner: "6526a9bd12f32bf4b0f37a1b", cType: "Product", cName: "Category"
      }

      axios
      .post("/details/storeOTP", parameter)
      .then((response) => {
        
        
        // didLoadStore(true)
        // document.title = "Cart - " + response.data.results[0].storeName
      })
      .catch(function (error) {
        // toast('Something went wrong, please try again')
      });
    }
    const handleFocus = (event) => event.target.select();
    return <>
      <div className="min-h-screen px-4 mb-20  text-center  "> 
          <span
           className="inline-block h-screen align-middle"
           aria-hidden="true"
         >     
           &#8203;
         </span>
         
           <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl  overflow-auto" style={{height:'auto' ,overflow:'auto'}}>
               <h2 className="text-xl    p-2 font-medium">
           One-Time Verification code
           </h2>
           <p className='text-gray-500 text-xs mb-10 mx-2'>Code has been sent to your email</p>
           <div class="grid grid-cols-6 gap-6 mx-6">
    <div>  <input
    onFocusCapture={validateValues}
    onBlur={validateValues}
    onFocus={handleFocus} 
    id={1} ref={currentOneFocus}onChange={(event)=>setDynamicFocus(event)} className='border border-gray-400 w-10 h-12 rounded-lg text-center font-bold' maxLength={1}/></div>
    <div>  <input   onFocus={handleFocus}  onFocusCapture={()=>validateValues()}  onBlur={()=>validateValues()} id={2} ref={currentTwoFocus} onChange={(event)=>setDynamicFocus(event)} className='border border-gray-400 w-10 h-12 rounded-lg text-center font-bold' maxLength={1}/></div>
    <div>  <input onFocus={handleFocus}   onFocusCapture={()=>validateValues()} onBlur={()=>validateValues()} id={3} ref={currentThreeFocus} onChange={(event)=>setDynamicFocus(event)} className='border border-gray-400 w-10 h-12 rounded-lg text-center font-bold' maxLength={1}/></div>
    <div>  <input onFocus={handleFocus}   onFocusCapture={()=>validateValues()} onBlur={()=>validateValues()} id={4} ref={currentFourFocus} onChange={(event)=>setDynamicFocus(event)} className='border border-gray-400 w-10 h-12 rounded-lg text-center font-bold' maxLength={1}/></div>
    <div>  <input onFocus={handleFocus}   onFocusCapture={()=>validateValues()}  onBlur={()=>validateValues()} id={5} ref={currentFiveFocus} onChange={(event)=>setDynamicFocus(event)} className='border border-gray-400 w-10 h-12 rounded-lg text-center font-bold' maxLength={1}/></div>
    <div>  <input onFocus={()=>validateValues()}   onFocusCapture={()=>validateValues()} onBlur={()=>validateValues()} id={6} ref={currentSixFocus} onChange={(event)=>setDynamicFocus(event)} className='border border-gray-400 w-10 h-12 rounded-lg text-center font-bold' maxLength={1}/></div>
      
  </div>
  
  <button 
  onClick={resendOTP}
  className='w-full rounded-full h-10 mt-10 text-blue-400 text-md'>{isLoading ? 'Resending..': 'Resend'}</button>
  {/* <button
  disabled={!isAllowButton}
  onClick={()=> alert('s')}
                 type="button"
              className='w-full rounded-full h-10  text-md'
                 style={{opacity: allowButton.current === false ? 1:0.2,
                        backgroundColor:'gray'
                      }}
               >  
                Verify 
               </button>  */}
  
  <img 
        style={{position:'absolute',top:30,right:20}}
       class={false ? "" :"ml-60 lg:w-12 lg:h-12 hover:opacity-80  m-2 shadow-lg md:w-12 md:h-full rounded w-16 h-16 md:block  " } src={store === null ? 'https://localflowershop.sgp1.digitaloceanspaces.com/product/1645683427653-flc-2022.jpeg': store.storeLogo}/>
           </div>
           
           </div>
  
    </>
  }
export default OTPContent;