import React ,{ Fragment, useState, useEffect,useMemo }from 'react';
// @flow 
// @flow

export default function MyModal(props) {
  
  let [addreType, setAddress] = useState(1)
const navigateIOS = (query)=>{
  let coordinates = `http://maps.apple.com/?q=${query}` //`http://maps.apple.com/?ll=10.317162,123.903571`
  window.open(coordinates, '_blank').focus();
}
const navigateGoogle = (query)=>{
  let coordinates = `https://www.google.com/maps/dir/?q=${query}` //`http://maps.apple.com/?ll=10.317162,123.903571`
  window.open(coordinates, '_blank').focus();
}
const renderAyalaButtons = ()=>{
if (addreType === 1) {
  return <>
      <button
onClick={()=>navigateGoogle('Ayala+Center+Cebu')}
type="button" class="text-white bg-green-600 hover:bg-green-800  focus:outline-none focus:ring-4 focus:ring-blue-300 font-xs rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
  Open Google Map
</button>
<button
onClick={()=>navigateIOS('Ayala+Center+Cebu')}
type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-4 focus:ring-blue-300 font-xs rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
  Open iOS Map
</button></>
}
}
const setBranchProps=(source)=>{
  setAddress(source)
  props.branch(source === 1  ? "at Base Line Residences" : "Ayala Center Cebu")
}
const renderCebu = ()=>{
 if (addreType=== 0) {
  return (
    <> <button
    onClick={()=>navigateGoogle('Base Line Residences, Juana Osmeña Street, Cebu City, Cebu')}
    type="button" class="text-white bg-green-600 mt-2 hover:bg-green-800  focus:outline-none focus:ring-4 focus:ring-blue-300 font-xs rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      Open Google Map
    </button>
    
    <button
    onClick={()=>navigateIOS('Base Line Residences, Juana Osmeña Street, Cebu City, Cebu')}
    type="button" class="text-white  bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-4 focus:ring-blue-300 font-xs rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      Open iOS Map
    </button></>
      )
 }
}
function location(props) {


  return (
      <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5    border-black border-2 rounded-lg" >
        <div className='MT-4 h-2'/>
      <label className="mt-2 block   tracking-wide text-black text-xs font-bold mb-2" for="grid-first-name">
     Branches
     </label> 
          <div className='mb-4  '>
          <p style={{color:'#353739'}}className='text-xs mb-2 '>{'GF Base Line Residence, Juana Osmeña St, Cebu City, 6000 Cebu'}</p>
         
          <button
onClick={()=>setBranchProps(0)}
type="button" class="hover:text-white  ease-in-out duration-300font-medium rounded-full text-xs px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"

style={{backgroundColor:addreType === 0 ?'black':'#dcdde1' ,color:addreType === 0 ?'white':'black'}}
>
Pickup here
</button>
<br/>
{renderCebu()}
<div class={ addreType === 1  ? "" : "" }>
          <p style={{color:'#353739'}} className='text-xs mt-4  mb-4'>{' 2nd floor at Ayala Center Cebu'}</p>



     
<button
onClick={()=>setBranchProps(1)}
type="button" class="text-black ease-in-out duration-300 hover:bg-black  font-medium rounded-full text-xs px-5 py-2.5 text-center mr-2 mb-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
style={{backgroundColor:addreType === 1 ?'black':'#dcdde1' ,color:addreType === 1 ?'white':'black'}}
>
Pick up here
</button>
<br/>
{renderAyalaButtons()}
</div>

          <div className='mb-4 h-2'/>
          </div>
      </div>
  );
}
return  location()
}


 
// export default location;