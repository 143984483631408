
import moment from 'moment-timezone'
export const ClosingTimeValidator = ()=>{


    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    let startDate  = ''
     startDate = moment(date.date).tz("Asia/Taipei").toDate()
    
    const currentDate = moment();
    const closingTime = moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
    const isAfter5PM = currentDate.isSameOrAfter(closingTime);
    const tomorrowDate = moment().add(1, 'days').startOf('day');
    let deliveryDate = moment(startDate).tz("Asia/Taipei")
    const isTomorrow = deliveryDate.isSame(tomorrowDate, 'day');
 
     if (isTomorrow && isAfter5PM) {
        return false
     } else {
        return true
     }
    
}


export const TimeValidator = (e)=>{

   console.log('TimeValidator',e)
    try {

    var date = e
    let startDate  = ''
    startDate = moment(date).tz("Asia/Taipei").toDate()
    const currentDate = moment();
    const closingTime = moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
    
      //* Convert selected date  - Done
      //* get converted Selected date  - Done
      //* compare to tomorrows Date - Done
      //* Check PH Time if its already 5pm or quarter to 5pm

     const isAfter5PM = currentDate.isSameOrBefore(closingTime);
     const tomorrowDate = moment().add(1, 'days').startOf('day')
  
     if (moment(e).isBefore(new Date())) {
       return {
         status:true,
         message:'Please select future dates'
       }
     } else if (moment(e).isSame(tomorrowDate)){
         if (isAfter5PM) {
           return {
             status:false,
             message:''
           }
         } else {
           return {
             status:true,
             message:`We accept orders for the following day (${moment(e).tz("Asia/Taipei").format("MMMM D, YYYY")}) until 5:00 pm today.` 
           }
         }
     } else {
       return {
         status:false,
         message:''
       }
     }
     
    } catch (error) {
        console.log(error)
        return false
    }
}