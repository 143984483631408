import React, { useState, useRef } from "react";
import "./App.css";
import Footer from "./component/Footer";
import CartView from "./component/CartView";
import PopupProduct from "./component/PopupProduct";
import CategoryView from "./component/CategoryItem";
import { PlaceholderCard } from "./component";
import { axios, productStats, axiosOTP } from "./Network";
import CardV2 from "./component/CardV2";
import toast, { Toaster } from "react-hot-toast";
import ContentLoader from "react-content-loader";
// import moment from 'moment'
import moment from "moment-timezone";
import { setHours, setMinutes } from "date-fns";

import ReactGA from "react-ga";
import { DayPicker } from "react-day-picker";
import { firstItem, secondtItem, thirdItem } from "../src/DefaultItems";
import { TimeValidator } from "../src/Utils/ClosingTimeValidator";
import "react-day-picker/dist/style.css";

function DatePickerComponent({ date, onChangeDate, navigator, store }) {
  const [isValid, setValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Please select future date");
  const bookedStyle = {
    border: "2px solid currentColor",
    backgroundColor: "#0652DD",
    color: "white",
  };
  let displayError = false;

  async function recordProductStats(e) {
    try {
      const data = {
        storeOwner: "TheFlowerLuxeCebu",
        cType: "calendarBehavior",
        cName: "website",
        userPreferredDate: moment(e).format("MMMM DD YYYY").toString(),
        date: new Date(),
      };
      const response = await productStats.put("/items", data);
      return response;
    } catch (error) {}
  }
  const validateDate = (e) => {
    if (TimeValidator(moment(e)).status) {
      toast.error(TimeValidator(moment(e)).message);
      setErrorMessage(TimeValidator(moment(e)).message);
      setValid(true);
      displayError = true;
    } else {
      recordProductStats(e);
      setValid(false);
      displayError = false;
      onChangeDate(e);
    }
  };
  const filterUnavailableDates = () => {
    try {
      return store.storeOptions.businessHours.unAvailableDates.map((item) => {
        return moment(item).toDate();
      });
    } catch {
      return [];
    }
  };
  const displayFooter = () => {
    let invalidContent = (
      <p className="animate-pulse text-xs text-red-500">{errorMessage}</p>
    );
    // let validContent =  <p className="text-xs">{''}</p>
    return !isValid ? null : invalidContent;
  };
  return (
    <DayPicker
      mode="single"
      value={date}
      defaultMonth={new Date()}
      fromMonth={new Date()}
      modifiers={{ booked: [date] }}
      modifiersStyles={{ booked: bookedStyle }}
      disabled={filterUnavailableDates()}
      today={new Date()}
      styles={{
        // head: 'custom-head',
        caption: { color: "#0652DD" },
      }}
      footer={displayFooter()}
      onDayClick={(e) => validateDate(e)}
      min={new Date()}
      required
    />
  );
}
ReactGA.initialize(process.env.REACT_APP_STORE_REACT_GA, {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123,
  },
});
var timeInManila = moment.tz("Asia/Manila");
const MyLoader = (props) => (
  <span>Loading...</span>
  // <ContentLoader viewBox="0 0 400 31" height={200} width={'auto'} {...props}>
  //   <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
  //   <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
  //   <circle cx="388" cy="12" r="12" />
  //   <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
  // </ContentLoader>
);

var id = process.env.REACT_APP_STORE_ID;
var primaryProduct = process.env.REACT_APP_DEFAULT_PRIMARY_PRODUCT;
var secondProduct = process.env.REACT_APP_DEFAULT_SECOND_PRODUCT;

// [Flux-Bouquet,Lux-Arrangements]
function App() {
  const storeRef = useRef(null);
  const [isActiveSearch, setActiveSearch] = useState(false);
  const [isDisplaySearched, setDisplaySearched] = useState(false);
  const [searchedResults, setSearchResults] = useState([]);
  const [searchedItem, setSearchedItem] = useState(null);
  const [status, setStatus] = useState(false);
  const [suggestStatus, setSuggestStatus] = useState(false);
  const [cart, setCartItems] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [scroll, setScroll] = useState(0);
  const [didUpdate, setUpdate] = useState(true);
  const [product, setProducts] = useState([]);
  const [addons, setAddonProduct] = useState([]);
  const [suggestedProduct, setSuggestedProduct] = useState([]);
  const [topItems, setTopItems] = useState([]);
  const [category, setSelectedCategory] = useState(primaryProduct); //
  const [categories, setCategory] = useState([]);
  const [storeDetails, setStoreInfo] = useState(null);
  const [hasVoucher, setHasVoucher] = useState(false);
  const [viewProduct, setCurrentView] = useState(null);
  const [selectedDate, setSelectedDate] = useState({ date: new Date() });
  const listInnerRef = useRef();
  const [offset, setOffset] = useState(0);
  const [currentPage, setPage] = useState(8);
  const [displayCalendar, setDisplayCalendar] = useState(false);

  const [selectedDateV2, setSelectedDateV2] = useState(
    moment(setHours(setMinutes(new Date(), 0), 9))
      .add(process.env.REACT_APP_DEFAULT_NUMBER_DAYS, "days")
      .tz("Asia/Manila")
      .toDate(),
  );
  const mainCategory = useRef();
  const [randomproducts, setRandomProducts] = useState([
    firstItem,
    secondtItem,
    thirdItem,
  ]);
  const [suggesteItem, setSuggestedItem] = useState({
    type: secondProduct,
    displayName: secondProduct,
    title: secondProduct,
  });
  var addonsID = "Tart-Addons";
  React.useEffect(() => {
    const preventRightClick = (event) => {
      event.preventDefault();
    };

    // Attach event listener to disable right-click on the entire document
    document.addEventListener("contextmenu", preventRightClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", preventRightClick);
    };
  }, []);
  React.useEffect(() => {
    console.log("i was rendered", cart.length);
    console.log("i was rendered", cart);
  }, [cart]);
  React.useEffect(async () => {
    let userSeletedDate = localStorage.getItem("userQ");
    var date = JSON.parse(userSeletedDate);
    if (date == undefined) {
      setOneDayAdvanceDelivery();
    } else {
      if (
        moment(date.date)
          .tz("Asia/Taipei")
          .add(process.env.REACT_APP_DEFAULT_NUMBER_DAYS, "days")
          .tz("Asia/Taipei")
          .toDate() <=
        moment(setHours(setMinutes(new Date(), 0), 13))
          .add(process.env.REACT_APP_DEFAULT_NUMBER_DAYS, "days")
          .tz("Asia/Taipei")
          .toDate()
      ) {
        setOneDayAdvanceDelivery();
      } else {
        setSelectedDate(date);
      }
    }

    try {
      window.onscroll = () => {
        listInnerRef.current = window.pageYOffset;
        setOffset(window.pageYOffset);
      };
      fetchStoreProduct(category, 8).then((data) => {
        if (data === undefined) {
          toast("Please try again later..");
        } else {
          productFilter(data.data.results, setProducts);

          setRandomItems(data.data.results);
        }
      });
      fetchStoreProduct(suggesteItem.type, 8).then((data) => {
        if (data === undefined) {
          toast("Please try again later..");
        } else {
          productFilter(data.data.results, setSuggestedProduct);
        }
      });
      loadProductCategory();
      let existingCart = localStorage.getItem("UserCart");
      let isAutoClear = process.env.REACT_APP_STORE_AUTO_CLEAR_CART;
      if (existingCart !== null) {
        if (isAutoClear === "true") {
          localStorage.removeItem("UserCart");
          localStorage.removeItem("UserCart_Cache");
          toast.success(`Fresh start! Your cart has been cleared`);
        } else {
          let jsonCart = JSON.parse(existingCart);
          setCartItems(jsonCart);
        }
      }
    } catch (error) {
      console.log("use effect error", error);
      toast("Please try again later..");
    }
  }, []);

  function setRandomItems(source) {
    try {
      let from = randomNumberInRange(1, source.length);
      let secondLoop = randomNumberInRange(1, source.length);
      let thirdLoop = randomNumberInRange(1, source.length);
      const randomItem = Math.floor(from * source.length);
      let item1 = source[from];
      let item2 = source[secondLoop];
      let item3 = source[thirdLoop];
      setRandomProducts([item1, item2, item3]);
    } catch (error) {}
  }

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const finder = (e) => {
    let userSeletedDate = localStorage.getItem("userQ");
    var date = JSON.parse(userSeletedDate);
    if (e != undefined) {
      var filterd = e.filter(
        (data) =>
          moment(data.date).format("YYYY-MM-DD") ===
          moment(date.date).format("YYYY-MM-DD"),
      );
      if (filterd.length) {
        return filterd[0];
      }
    }
  };

  const productFilter = (e, state) => {
    var filtered = [];
    e.map((item) => {
      var price = finder(item.priceSurgesDates);
      if (
        item.priceSurgesDates != undefined &&
        item.priceSurgesDates.length &&
        price != undefined
      ) {
        console.log();
        item.basePrice = item.price;
        item.price = price.price;

        filtered.push(item);
      } else {
        filtered.push(item);
      }
    });
    state(filtered);
    setStatus(false);
    setSuggestStatus(false);
  };
  const setOneDayAdvanceDelivery = () => {
    var startDate = moment(setHours(setMinutes(new Date(), 0), 13))
      .add(2, "days")
      .toDate();
    var searchDetails = {
      date: startDate,
      type: "Flux-Bouquet",
    };
    setSelectedDate(startDate);
    localStorage.setItem("userQ", JSON.stringify(searchDetails));
  };

  function addRemoveItem(e, status) {
    try {
      // Add Item
      if (status) {
        if (cart.length) {
          if (cart.find((item) => item._id === e._id) === undefined) {
            setCartItems((prevArray) => [...prevArray, e]);
            let existingCart = localStorage.getItem("UserCart");
            let jsonCart = JSON.parse(existingCart);
            e.datedAdded = new Date();
            jsonCart.push(e);
            localStorage.setItem("UserCart", JSON.stringify(jsonCart));
            localStorage.setItem("UserCart_Cache", JSON.stringify(jsonCart));
            toast.success(`${e.title} added`);
          }
        } else {
          // New
          let initialCart = [];
          e.datedAdded = new Date();
          initialCart.push(e);
          localStorage.setItem("UserCart", JSON.stringify(initialCart));
          localStorage.setItem("UserCart_Cache", JSON.stringify(initialCart));
          setCartItems((prevArray) => [...prevArray, e]);
          toast.success(`${e.title} added`);
        }
      } else {
        let filteredCart = cart.filter((item) => item._id !== e._id);
        setCartItems(filteredCart);
        localStorage.setItem("UserCart", JSON.stringify(filteredCart));
      }
      setUpdate(true);
    } catch (error) {
      console.log("addRemoveItem", error);
      toast.error("Please clear cart");
    }
  }

  function loadSuggestedOnly(type, page) {
    try {
      fetchStoreProduct(type, page).then((data) => {
        setSuggestedProduct([]);
        productFilter(data.data.results, setSuggestedProduct);
      });
    } catch (error) {
      setSuggestedProduct([]);
    }
  }
  function navigateTo() {
    storeRef.current.scrollIntoView();
  }

  function eventDateNavigate(item) {
    saveDateState(
      moment(setHours(setMinutes(new Date(), 0), 9))
        .add(1, "days")
        .tz("Asia/Manila")
        .toDate(),
    );
    addRemoveItem(item, true);
    //  searchAvailableDateService()
    // storeRef.current.scrollIntoView()
  }

  function reload(type, page, withSuggested) {
    ReactGA.outboundLink(
      {
        label: `User searched item : ${(type, JSON.parse(localStorage.getItem("userQ")))}`,
      },
      function () {
        console.log("");
      },
      ["Search-Tracker"],
    );

    if (!withSuggested) {
      mainCategory.current = type;
    }
    localStorage.setItem("UserCategory_Cache", JSON.stringify(type));
    setDisplaySearched(false);
    setSearchedItem(null);
    setActiveSearch(false);
    setStatus(true);
    setSelectedCategory(type);
    fetchStoreProduct(type, page).then((data) => {
      setProducts([]);
      // setProducts(data.data.results)
      productFilter(data.data.results, setProducts);
      // window.scrollTo(0, 1020)
    });
    if (withSuggested) {
      let suggested = categories.filter(
        (data) => data.title != type && data.status == true,
      );
      var item = suggested[Math.floor(Math.random() * suggested.length)];
      try {
        setSuggestedItem(item);
        fetchStoreProduct(item.title, page).then((data) => {
          setSuggestedProduct([]);
          setSuggestedProduct(data.data.results, setProducts);
        });
      } catch (error) {
        setSuggestedProduct([]);
      }
    }
  }
  function loadProductCategory() {
    let parameter = {
      id: id,
      equalTo: true,
      storeOwner: id,
      cType: "Product",
      cName: "Category",
    };
    axios
      .post("/details/Store", parameter)
      .then((response) => {
        // setSelectedCategory(response.data.results[0].productCategories.filter(item => item.status === true))
        setCategory(response.data.results[0].productCategories);
        setStoreInfo(response.data.results[0]);
        setHasVoucher(
          response.data.results[0].storeOptions.siteHeaderNotice.status,
        );
        document.title = response.data.results[0].storeName;
      })
      .catch(function (error) {
        console.log("fetching product", error);
      });
  }
  async function fetchStoreProduct(item, page) {
    try {
      var defaultDate = new Date();
      var convertedUserDate = moment(defaultDate)
        .tz("Asia/Manila")
        .format("yyyy-mm-dd");
      let userSeletedDate = localStorage.getItem("userQ");
      var date = JSON.parse(userSeletedDate);
      setSelectedDate(date);
      const data = {
        id: item,
        queryType: "filter",
        storeOwner: id,
        isAPI: true,
        showLimit: true,
        number: page,
        deliveryDate: moment(date.date).format("YYYY-MM-DD"),
      };
      const response = await axios.post("/productV2/Product", data);
      return response;
    } catch (error) {
      toast("Please try again later..");
    }
  }
  function renderCategories() {
    var data = [];
    categories.map((item) => {
      if (item.status) {
        data.push(
          <CategoryView
            onClick={(e) => reload(e, 8)}
            active={category}
            data={item}
          />,
        );
      }
    });
    return data;
  }
  function renderItem(source) {
    var data = [];
    var sortedProduct = source.sort(
      (a, b) => parseInt(a.price) - parseInt(b.price),
    );
    var sourceItem = sortedProduct;
    var activePromo = storeDetails.storeOptions.activeCategoryPromo; //[0].category
    if (sourceItem.length) {
      sourceItem.map((item) => {
        var isAdded =
          cart.filter((data) => data._id === item._id).length === 0
            ? false
            : true;
        data.push(
          <>
            <CardV2
              isDisabled={status}
              activePromo={activePromo}
              type="Display"
              view={(e) => setCurrentView(e)}
              update={didUpdate}
              existing={isAdded}
              item={item}
              didAdd={(item) => addRemoveItem(item, true)}
              didRemove={(item) => addRemoveItem(item, false)}
            />
            {/* <Card activePromo={activePromo} type="Display" view={(e) => setCurrentView(e)} update={didUpdate} existing={isAdded} item={item} didAdd={(item) => addRemoveItem(item, true)} didRemove={(item) => addRemoveItem(item, false)} /> */}
          </>,
        );
      });
      return data;
    } else if (!status) {
      return (
        <div className="w-full">
          {/* <img src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1645683427653-flc-2022.jpeg" className="h-[90] w-auto mx-2 " />  */}
          <ContentLoader height="200" width="200" viewBox="0 0 265 230">
            <rect x="15" y="15" rx="4" ry="4" width="350" height="25" />
            <rect x="15" y="50" rx="2" ry="2" width="350" height="150" />
            <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
            <rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
          </ContentLoader>
          <span className="w-full  text-center text-sm text-gray-500">
            Empty collection for{" "}
            <span className="text-red-400">
              {moment(selectedDate.date).format("MMMM DD YYYY").toString()}{" "}
            </span>
          </span>
        </div>
      );
    } else {
      for (var i = 0; i < 4; i++) {
        data.push(<PlaceholderCard />);
      }
      return data;
    }
  }

  const displayNotice = () => {
    var content = <div></div>;
    if (
      storeDetails !== null &&
      storeDetails.storeOptions.siteHeaderNotice.status
    ) {
      var details = storeDetails.storeOptions.siteHeaderNotice;

      content = (
        <div
          className="lg:h-15 duration-400 sticky top-0 z-50  flex-1 transform overflow-y-auto transition sm:h-10 "
          style={{
            backgroundColor:
              storeDetails.storeOptions.colorTheme.componentColor,
          }}
        >
          <div className="... text-4 mt-2 flex w-full  items-center justify-center font-extralight uppercase  tracking-tight text-gray-900 ">
            {details.value}
          </div>
        </div>
      );
    }

    return content;
  };

  const loadItem = (item, page, primary) => {
    setPage(page);
    if (primary) {
      reload(item, page, false);
    } else {
      loadSuggestedOnly(item, page, true);
      setSuggestStatus(true);
    }
  };

  const validateCart = () => {
    let existingCart = localStorage.getItem("UserCart");
    if (existingCart !== null) {
      let jsonCart = JSON.parse(existingCart);
      setCartItems(jsonCart.length == 0 ? [] : jsonCart);
    } else {
      setCartItems([]);
    }
  };
  const saveDateState = (startDate, title) => {
    setSelectedDateV2(startDate);
    var convertedTime = moment(startDate).tz("Asia/Manila").toDate();
    var searchDetails = {
      date: convertedTime,
      type: category,
    };
    localStorage.setItem("userQ", JSON.stringify(searchDetails));
  };

  const searchService = () => {
    setSearchedItem(
      categories.filter((data) => data.title == category)[0].displayName,
    );
    let parameter = {
      owner: id,
      value:
        searchedItem === null
          ? categories.filter((data) => data.title == "Flux-Bouquet")[0]
              .displayName
          : searchedItem,
    };
    setActiveSearch(false);
    setStatus(true);
    axios
      .post("/smartSearchV2", parameter)
      .then((response) => {
        setStatus(false);
        setActiveSearch(true);
        if (response.data.results.length) {
          // setProducts([])
          setSearchResults([]);
          setDisplaySearched(true);
          productFilter(response.data.results, setSearchResults);
        } else {
          setSearchResults([]);
          setDisplaySearched(false);
        }

        // setSelectedCategory(response.data.results[0].productCategories.filter(item => item.status === true))
        // setCategory(response.data.results[0].productCategories)
        // // setTopItems(response.data.results[0].topItems)
        // console.log('response.data.results[0]',response.data.results[0])
        // setStoreInfo(response.data.results[0])
        // setHasVoucher(response.data.results[0].storeOptions.siteHeaderNotice.status)
        // document.title = 'Search results'
      })
      .catch(function (error) {
        console.log("fetching product", error);
      });
  };
  const emptyComponent = () => {
    return (
      <div className="group  mx-auto max-w-screen-xl px-4 py-8 group-hover:text-red-200 ">
        <h2 className="mt-1 text-2xl font-extrabold  uppercase tracking-wide lg:text-3xl">
          {searchedItem}
        </h2>
        <ContentLoader height="200" width={"auto"} viewBox="0 0 265 230">
          <rect x="15" y="15" rx="4" ry="4" width="350" height="25" />
          <rect x="15" y="50" rx="2" ry="2" width="350" height="150" />
          <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
          <rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
        </ContentLoader>
        <ContentLoader height="200" width={"auto"} viewBox="0 0 265 230">
          <rect x="15" y="15" rx="4" ry="4" width="350" height="25" />
          <rect x="15" y="50" rx="2" ry="2" width="350" height="150" />
          <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
          <rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
        </ContentLoader>

        {/* <img src="https://static.vecteezy.com/system/resources/previews/006/208/684/original/search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg" className="h-96 w-auto mx-2 " />  */}
        <span className="w-full  text-center text-sm text-gray-500">
          Loading collection for{" "}
          <span className="text-red-400">
            {moment(selectedDate.date).format("MMMM DD YYYY").toString()}{" "}
          </span>
        </span>
      </div>
    );
  };
  const searchComponent = () => {
    let searchResultContent = (
      <div>
        <div className="group  mx-auto max-w-screen-xl px-4 py-8 group-hover:text-red-200">
          {storeDetails === null ? null : (
            <span className="flex items-center "></span>
          )}
          <div>
            <span className="inline-block h-1 w-12 bg-red-200"></span>
            <span className="flex items-center ">
              {" "}
              <h5 className=" text-xs font-medium  text-black/90">
                Available items for{" "}
                <span className="ml m rounded-full font-bold text-blue-800">
                  {" "}
                  <span className="m-2">
                    {moment(selectedDate.date)
                      .format("MMMM DD YYYY")
                      .toString()}
                  </span>
                </span>
              </h5>{" "}
              <img
                src="https://img.icons8.com/material-outlined/344/calendar--v1.png"
                className="mx-2 h-4 w-4 "
              />
            </span>
            <h2 className="mt-1 text-2xl font-extrabold  uppercase tracking-wide lg:text-3xl">
              {searchedItem}
            </h2>
            <h5
              className={`text-sm   ${searchedResults.length === 0 ? "text-white" : "text-xs font-medium text-gray-500"}`}
            >
              {searchedResults.length} items foundz
            </h5>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-x-4 gap-y-8 lg:grid-cols-4">
            {storeDetails != null ? renderItem(searchedResults) : null}
          </div>
        </div>
      </div>
    );

    let emptyResults = (
      <a href="javascript:void(0);" onClick={() => setActiveSearch(false)}>
        <div className="relative  mx-auto mb-20 mt-10 w-full  max-w-md items-center justify-center rounded-3xl text-center">
          <span className="items-center text-xl  font-bold ">
            '{searchedItem}'
          </span>
          <img
            className="ml-2"
            src="https://cdn.dribbble.com/users/2387623/screenshots/7026223/media/b6d3d311773a99fc8515c591cd069909.jpg?compress=1&resize=1600x1200&vertical=top"
            width={400}
            height={400}
          />
          <span className="items-center text-xl  font-bold">Not found</span>
        </div>
      </a>
    );
    const validateKeyboard = (e) => {
      if (e.key === "Enter") {
        searchService();
      }
    };
    return (
      <>
        <div className="mx-auto max-w-md "></div>
        <h5 className=" mb-2 ml-32  text-sm text-black/90 md:ml-4 lg:ml-32"></h5>
        <span>
          <h5 className=" ml-4 text-xs text-black/20 lg:ml-32 ">
            Tap to change the date
          </h5>
        </span>
        <div
          showSoftInputOnFocus={false}
          onClick={() => setDisplayCalendar(true)}
          maxLength={30}
          // style={{borderColor:storeDetails === null ? 'white':storeDetails.storeOptions.colorTheme.buttonColor}}
          class="align-center  mt mb outline-none peer  ml-4   mt-2  flex h-10 w-auto  items-center justify-center rounded-full border bg-blue-800 pl-4 text-sm font-bold text-blue-800 text-white duration-300 ease-in-out hover:shadow-lg lg:ml-32 lg:w-60 "
          type="text"
          id="searchV2"
          disabled={displayCalendar}
          value={moment(selectedDateV2).format("MMMM DD YYYY").toString()} //"Choose Date "
          // disabled={status}
          onKeyDown={(e) => validateKeyboard(e)}
          onChange={(e) => setSearchedItem(e.nativeEvent.target.value)}
          placeholder="Choose Date"
        >
          <span className=" text-white">
            Order for {moment(selectedDateV2).format("MMM DD, YYYY").toString()}{" "}
          </span>
          <img
            src="https://img.icons8.com/material-outlined/344/calendar--v1.png"
            className="ml-2 h-4 w-4 rounded-sm bg-white "
          />
        </div>

        <div className="mb-10 mt-10" />
        <div
          className={
            displayCalendar
              ? "duration-400 visible mt-2 transform transition"
              : "hidden"
          }
        >
          <div className=" ml-8 grid grid-cols-1  content-center gap-2 md:grid-cols-5 lg:grid-cols-3">
            <div className="w-full" />
            <div className="justify w-80 content-center rounded-lg border border-gray-100 shadow-lg sm:ml-2 md:ml-20 lg:ml-20">
              {storeDetails != null ? (
                <DatePickerComponent
                  store={storeDetails}
                  date={selectedDateV2}
                  onChangeDate={(e) => saveDateState(e)}
                  validate={() => validateCart()}
                />
              ) : null}
              <button
                onClick={searchAvailableDateService}
                className="mb   mb-2 w-full  rounded-full font-bold text-blue-700"
              >
                Search available flowers
              </button>
            </div>
            <div className="w-full" />
          </div>
        </div>
        <div className="mb-10 mt-10" />
        {displayCalendar ? null : renderCategory()}
        {searchedResults.length === 0
          ? isActiveSearch
            ? emptyResults
            : null
          : null}

        {isDisplaySearched ? searchResultContent : null}
        <div className="mb-20 md:mb-40 lg:mb-20" />
      </>
    );
  };
  const searchAvailableDateService = (e) => {
    setDisplayCalendar(false);
    localStorage.removeItem("UserCart");
    validateCart();
    reload(category, currentPage, true);
  };
  const displayMainProduct = () => {
    try {
      return (
        <div className="margin: 20rem my-40  text-center sm:text-center lg:text-center  ">
          <h1 className="text-4xl  font-extralight uppercase  tracking-tight text-gray-900 sm:text-2xl md:text-6xl">
            <span className="block xl:inline">
              {categories.length
                ? categories.filter((data) => data.title == category)[0]
                    .displayName
                : null}
            </span>{" "}
            <span className="text-themebg-600 block xl:inline">Collection</span>
          </h1>
          <span className="text-themebg-600 mt-10 block text-xs font-medium xl:inline">
            {product.length} items found
          </span>
        </div>
      );
    } catch (error) {
      return <span></span>;
    }
  };
  const renderCategory = () => {
    var list = [];
    try {
      storeDetails.productCategories.map((item) => {
        if (item.status) {
          list.push(<>{categoryComponent(item)}</>);
        }
      });
    } catch {
      return null;
    }
    return (
      <div className="sticky">
        <div style={{ background: "red" }}></div>
        <h5 className=" ml-4 text-sm  text-black/90 lg:ml-32"></h5>
        <span>
          <h5 className=" ml-4 text-xs  text-black/20 lg:ml-32">
            Search by category
          </h5>
        </span>
        <div className="scroll-smooth w-11/12 items-center overflow-auto overflow-x-visible whitespace-nowrap  md:ml-20 lg:ml-28 lg:whitespace-normal ">
          {list}
        </div>
      </div>
    );
  };
  // storeDetails.storeOptions.colorTheme.buttonColor
  const categoryStats = (item) => {
    reload(item.title, currentPage, true);
    recordCategoryStats(item);
  };

  async function recordCategoryStats(e) {
    try {
      const data = {
        storeOwner: "TheFlowerLuxeCebu",
        cType: "categoryBehavior",
        cName: "website",
        selectedCategory: e,
        date: new Date(),
      };
      const response = await productStats.put("/Items", data);
      return response;
    } catch (error) {}
  }
  const categoryComponent = (item) => {
    return (
      <button
        onClick={() => categoryStats(item)}
        disabled={status === false ? false : true}
        type="button"
        className={
          1 !== 0
            ? "hover:scroll-auto focus:outline-none my-2   ml-4 inline-flex justify-center rounded-full border border-black border-transparent px-4 py-2 text-sm font-medium text-white duration-300 ease-in-out hover:shadow-lg focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            : "focus:outline-none ml-4   inline-flex justify-center rounded-full border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-white focus:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        }
        style={{
          opacity: status ? (category === item.title ? 1 : 0.4) : 1,
          backgroundColor: category === item.title ? "black" : "white",
          borderColor:
            category === item.title
              ? null
              : storeDetails === null
                ? "white"
                : "#ecf0f1",
          color: category === item.title ? "white" : "black",
        }}
      >
        {status ? (
          <>
            {" "}
            <div className="">
              <span className="font-sm ">{item.displayName}</span>
            </div>
            {category != item.title ? (
              <div className="">
                <span className="font-sm ml">+</span>
              </div>
            ) : (
              <div className="">
                <svg
                  version="1.1"
                  id="loader-1"
                  className="ml-2 h-5 w-5 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 40 40"
                  enable-background="new 0 0 40 40"
                  space="preserve"
                >
                  <path
                    opacity="0.2"
                    fill="#FFFF"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
       s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
       c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path
                    fill="#FFFF"
                    className="border-2 border-white"
                    d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
       C22.32,8.481,24.301,9.057,26.013,10.047z"
                  ></path>
                </svg>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="">
              <span className="font-sm ">{item.displayName} +</span>
            </div>
            <a href="javascript:void(0);">
              {/* <svg xmlns="http://www.w3.org/2000/svg" className="lg:ml-2 md:ml-2 h-5 w-5" fill="none" viewBox="0 0 20 20" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
</svg> */}
            </a>{" "}
          </>
        )}
      </button>
    );
  };

  const renderCategoryDisplayName = () => {
    try {
      return categories.length
        ? categories.filter((data) => data.title == category)[0].displayName
        : null;
    } catch (error) {
      return <>....</>;
    }
  };
  const isEmpty = () => {
    if (product.length === 0 && !isActiveSearch && !status) {
      return true;
    } else {
      return false;
    }
  };
  const copyCode = () => {
    navigator.clipboard.writeText("VDAYREADY2023");
    toast.success("Copied: VDAYREADY2023");
  };
  const mainContent = () => {
    return (
      <div ref={storeRef}>
        {/* {displayNotice()} */}
        {1 > 300 ? null : (
          <div className="xs:bg-white md:bg-red  bg-white sm:bg-white lg:bg-white">
            {/* <FixedDatePicker  storeDetails={storeDetails} validate={()=>validateCart()}className="" tapped={(selectedDate,item)=>reload(item,currentPage,true)} categories={categories} isLoading={status} /> */}
            {/* {storeDetails !== null ? <div><LandingHeader didScroll={() => window.scrollTo(0, 1020)} details={storeDetails} /> </div>: <MyLoader /> } */}

            {/* {storeDetails !== null ?  <FixedDatePicker storeDetails={storeDetails} validate={()=>validateCart()}className="" tapped={(selectedDate,item)=>reload(item,currentPage,true)} categories={categories} isLoading={status} />: null} */}
            {/* hidden sm:visible md:visible */}
            <div className="mt-2 h-4 w-full lg:mt-4 lg:h-10" />
            {/* <div className="mt-20overflow-hidden shadow-lg bg-white hidden md:visible rounded-lg h-90 w-60 md:w-80 cursor-pointer m-auto " style={{marginTop:100}}>
    <a href="#" className="w-full block h-full">
        <img alt="blog photo" src={storeDetails != null ? storeDetails.welcomeMessageV2.landingImage : ''} className="max-h-40 w-full object-cover"/>
        <div className="bg-white dark:bg-gray-800 w-full p-4">
            <p className="text-indigo-500 text-md font-medium">
            </p>
            <p className="text-gray-800 dark:text-white text-xl font-medium mb-2">
                {storeDetails != null ? storeDetails.welcomeMessageV2.header : ''}
            </p>
            <p className="text-gray-400 dark:text-gray-300 font-light text-md">
            {storeDetails != null ?  storeDetails.welcomeMessageV2.subDetails : ''}
            </p>
        </div>
    </a>
</div> */}

            {searchComponent()}

            {/* <div className="lg:ml-32 ml-4 " >
    <p className="text-xs text-black/20 ">Available Promo Code</p>
  <img
  onClick={()=> copyCode()}
  className="hover:shadow-lg rounded-lg  ease-in-out duration-300"
  src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1673248658755-voucher-1.png"
  width={200}
  height={20}
  />
  </div> */}

            {/* <TestCalendar/> */}
            {/* {renderCategory()} */}

            {/* <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div className="md:flex">
    <div className="md:shrink-0">
      <img className="h-48 w-full object-cover md:h-full md:w-48" src="/img/store.jpg" alt="Man looking at item at a store"/>
    </div>
    <div className="p-8">
      <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case study</div>
      <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Finding customers for your new business</a>
      <p className="mt-2 text-gray-500">Getting a new business off the ground is a lot of hard work. Here are five ideas you can use to find your first customers.</p>
    </div>
  </div>
</div> */}
          </div>
        )}

        {cart.length >= 1 ? (
          <CartView
            storeDetails={storeDetails}
            removeItem={(item) => addRemoveItem(item, false)}
            items={cart}
          />
        ) : null}
        {/* {displayMainProduct()} */}
        {/* listInnerRef.current >= 300 ?  */}
        {/* <div className="sm:text-center  text-center lg:text-center  margin: 20rem my-40  " >
        <h1 className="uppercase  text-4xl tracking-tight  font-extralight text-gray-900 sm:text-2xl md:text-6xl">
          <span className="block xl:inline">{
            categories.length ? categories.filter(data => data.title == category)[0].displayName : null
          }</span>{' '}
          <span className="block text-themebg-600 xl:inline">Collection</span>
        </h1>
        <span className="block text-themebg-600 xl:inline mt-10">{product.length} items found</span>
      </div> */}
        <div style={{ opacity: status ? 0.2 : 1 }}>
          <div className="divide-y divide-gray-200">
            <div>
              {/* {storeDetails === null ? null : <span className="ml-20 flex items-center text-gray-500">Available items for <span className="text-blue-400 ml-2"> {moment(selectedDate.date).format("MMMM DD YYYY").toString()}</span><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /></span> } */}
              <div className="grid gap-0 sm:grid-cols-2  md:grid-cols-3  lg:grid-cols-4">
                {/* {storeDetails != null ? renderItem(product) : null} */}
              </div>

              {/* {storeDetails === null ? null : <span className=" flex items-center text-gray-500">Available items for <span className="text-blue-400 ml-2"> {moment(selectedDate.date).format("MMMM DD YYYY").toString()}</span><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /></span> }
    <div>
      <span className="inline-block w-12 h-1 bg-red-200"></span>
          <h2 className="mt-1 text-2xl font-extrabold tracking-wide uppercase lg:text-3xl">
          { suggesteItem.displayName}
      </h2>
      <span className="block text-themebg-600 xl:inline mt-10">{suggestedProduct.length} items found</span>
    </div> */}
              {isEmpty() ? (
                emptyComponent()
              ) : (
                <section>
                  <div className="group mx-auto max-w-screen-xl px-4 py-8 group-hover:text-red-200">
                    {storeDetails === null ? null : (
                      <span className="flex items-center ">
                        {" "}
                        <h5 className=" text-xs  text-black/90">
                          Available items for{" "}
                          <span className="m  rounded-full font-bold text-blue-800">
                            {" "}
                            <span className="m-2">
                              {moment(selectedDate.date)
                                .format("MMMM DD, YYYY")
                                .toString()}
                            </span>
                          </span>
                        </h5>{" "}
                        <img
                          src="https://img.icons8.com/material-outlined/344/calendar--v1.png"
                          className="mx-2 h-4 w-4 "
                        />
                      </span>
                    )}

                    <div>
                      <span className="inline-block h-1 w-12 bg-red-200"></span>
                      <h2 className="mt-1 text-2xl font-extrabold uppercase tracking-wide lg:text-3xl">
                        {renderCategoryDisplayName()}
                      </h2>
                      <h5
                        className={`text-sm   ${product.length === 0 ? "text-white" : "text-xs font-medium text-gray-500"}`}
                      >
                        {/* <h5 className=" text-xs  text-black/90">{product.length} */}
                        {product.length} items found
                      </h5>
                    </div>
                    <div className="mt-8 grid grid-cols-2 gap-x-4 gap-y-8 lg:grid-cols-4">
                      {storeDetails != null ? renderItem(product) : null}
                    </div>
                  </div>
                </section>
              )}

              <div className="mb-10  text-center">
                <button
                  disabled={status}
                  onClick={() =>
                    loadItem(
                      categories.filter((data) => data.title == category)[0]
                        .title,
                      currentPage + 100,
                      true,
                    )
                  }
                  type="button"
                  className={`focus:outline-none my-2  ml-4 inline-flex justify-center rounded-full border border-transparent bg-black px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 ${product.length === 0 ? "hidden" : "visible"}`}
                  style={{
                    backgroundColor:
                      storeDetails === null
                        ? "white"
                        : storeDetails.storeOptions.colorTheme.buttonColor,
                  }}
                >
                  Show More{" "}
                  {status ? (
                    <div>
                      <svg
                        version="1.1"
                        id="loader-1"
                        className="ml-2 h-5 w-5 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="40px"
                        height="40px"
                        viewBox="0 0 40 40"
                        enable-background="new 0 0 40 40"
                        space="preserve"
                      >
                        <path
                          opacity="0.2"
                          fill="#FFFF"
                          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                        />
                        <path
                          fill="#FFFF"
                          className="border-2 border-white"
                          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                            C22.32,8.481,24.301,9.057,26.013,10.047z"
                        ></path>
                      </svg>
                    </div>
                  ) : null}
                </button>
              </div>

              {/* <div className="mt-40" />
              <section className="relative mb-20 mt-20 overflow-hidden rounded-lg pb-80 shadow-2xl lg:m-0 lg:pb-0">
                <div className="ml-auto p-8 text-center sm:p-12 lg:w-2/3">
                  <p className="text-sm font-semibold uppercase tracking-widest">
                    Good news for Credit Card users
                  </p>

                  <h2 className="mt-6 font-black uppercase">
                    <span className="text-3xl font-black sm:text-3xl">
                      Enjoy flexible payment options
                    </span>

                  </h2>

                  <a
                    className="mt-8 inline-block w-full rounded-full bg-black py-4 text-sm font-bold uppercase tracking-widest text-white"
                    href="https://apps.apple.com/us/app/id1570962515"
                  >
                    USE APP
                  </a>

                  <p className="mt-12 text-xs font-medium uppercase text-gray-400">
                    *Simply place your order*
                  </p>
                </div>

                <div className="absolute bottom-0  left-0 h-80 w-full lg:h-full lg:w-1/3">
                  <img
                    alt="Trainer"

                    src="https://assets-global.website-files.com/60585b03d6e6e9a1ac739495/61efb566410dcbad7cd6ed08_payment%20methods.png"
                    className="absolute h-full w-full object-cover "
                  />
                </div>
              </section> */}

              <section className="mt-2">
                <div className="mx-auto max-w-screen-xl px-4 py-8">
                  {storeDetails === null ? null : (
                    <span className=" flex items-center ">
                      {" "}
                      <h5 className=" text-xs font-medium text-black/90">
                        Available items for{" "}
                        <span className="ml-2 font-bold text-blue-800">
                          {" "}
                          {moment(selectedDate.date)
                            .format("MMMM DD, YYYY")
                            .toString()}
                        </span>
                      </h5>
                      <img
                        src="https://img.icons8.com/material-outlined/344/calendar--v1.png"
                        className="mx-2 h-4 w-4 "
                      />
                    </span>
                  )}
                  <div>
                    <span className="inline-block h-1 w-12 bg-red-200"></span>
                    <h2 className="mt-1 text-2xl font-extrabold uppercase tracking-wide lg:text-3xl">
                      {suggesteItem.displayName}
                    </h2>
                    <h5 className=" text-sm  text-gray-500">
                      {suggestedProduct.length} items found
                    </h5>
                  </div>
                  <div className="mt-8 grid grid-cols-2 gap-x-4 gap-y-8 lg:grid-cols-4">
                    {storeDetails != null ? renderItem(suggestedProduct) : null}
                  </div>
                </div>
              </section>

              <div className="mb-20 text-center ">
                <button
                  disabled={status}
                  onClick={() =>
                    loadItem(suggesteItem.title, currentPage + 20, false)
                  }
                  type="button"
                  className={
                    "focus:outline-none my-2  ml-4 inline-flex justify-center rounded-full border border-transparent px-4  py-2 text-sm font-medium text-white hover:bg-gray-500 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  }
                  style={{
                    opacity: status ? 0.0 : 1,
                    backgroundColor:
                      storeDetails === null
                        ? "white"
                        : storeDetails.storeOptions.colorTheme.buttonColor,
                  }}
                >
                  Show More{" "}
                  {suggestStatus ? (
                    <svg
                      version="1.1"
                      id="loader-1"
                      className="ml-2 h-5 w-5 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="40px"
                      height="40px"
                      viewBox="0 0 40 40"
                      enable-background="new 0 0 40 40"
                      space="preserve"
                    >
                      <path
                        opacity="0.2"
                        fill="#FFFF"
                        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                      />
                      <path
                        fill="#FFFF"
                        className="border-2 border-white"
                        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
                      ></path>
                    </svg>
                  ) : null}
                </button>
              </div>

              {/* <div className="sm:text-center text-center lg:text-left-1 sticky margin: 20rem  mt-20 " >
              <h1 className="uppercase text-4xl tracking-tight font-extralight text-gray-900 sm:text-2xl md:text-6xl">
                <span className="block xl:inline">HOTTEST </span>
                <span className="block text-themebg-600 xl:inline">Collection</span>
              </h1>
            </div>
            <div className="grid  gap-0  lg:grid-cols-4   md:grid-cols-3  sm:grid-cols-2 " >
            {storeDetails != null ?  renderItem(topItems) : null}
            </div> */}
            </div>
          </div>

          {/* <div className="f-f-l mb-10 mt-40 flex flex-col items-center pt-3 text-black">
            <h1 className="text-1xl font-extralight uppercase text-gray-500">
              Mode of Payments
            </h1>
            <div className="my text-color f-f-l text-base">
              <ul className="flex items-center">
                <div className="w-col w-col-3 w-col-small-6  w-col-tiny-6 mr-2 opacity-100 duration-300 ease-in-out hover:opacity-60 hover:shadow-lg">
                  <img
                    src="https://mindanaotimes.com.ph/wp-content/uploads/2021/05/GCash-Logo-Transparent-PNG-1.png"
                    loading="lazy"
                    width="100"
                    alt=""
                    className="rounded-full "
                  />
                </div>
                <div className="w-col w-col-3 w-col-small-6 w-col-tiny-6 mr-2 duration-300 ease-in-out hover:opacity-60 hover:shadow-lg">
                  <img
                    src="https://cdn.pixabay.com/photo/2015/05/26/09/37/paypal-784404_960_720.png"
                    loading="lazy"
                    width="100"
                    alt=""
                  />
                </div>
                <div className="w-col w-col-3 w-col-small-6  w-col-tiny-6 mr-2 duration-300 ease-in-out hover:opacity-60 hover:shadow-lg">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeY_qmsYG087aTCUUQF3QAP9lJ0AUlWXbwG6unHultnHNg1mAH01mbEczaUhMI4z9f5Jc&usqp=CAU"
                    loading="lazy"
                    width="100"
                    alt=""
                  />
                </div>
                <div className="w-col w-col-3 w-col-small-6  w-col-tiny-6 mr-2 duration-300 ease-in-out hover:opacity-60 hover:shadow-lg">
                  <img
                    src="http://www.gtcapital.com.ph/storage/uploads/2017/09/59bc94ce59565.png"
                    loading="lazy"
                    width="100"
                    alt=""
                  />
                </div>
                <div className="w-col w-col-3 w-col-small-6  w-col-tiny-6 mr-2 duration-300 ease-in-out hover:opacity-60 hover:shadow-lg">
                  <img
                    src="https://orangemagazine.ph/wp-content/uploads/2022/12/Paymongo-Logo.png"
                    loading="lazy"
                    width="100"
                    alt=""
                  />
                </div>
              </ul>
            </div>
          </div> */}

          {storeDetails !== null ? (
            <Footer details={storeDetails} />
          ) : (
            <MyLoader />
          )}
          <PopupProduct
            didAdd={(item) => addRemoveItem(item, true)}
            storeDetails={storeDetails}
            removeItem={(item) => addRemoveItem(item, false)}
            items={[1, 2, 3, 4, 5]}
          />
        </div>
        <Toaster />
      </div>
    );
  };

  const displayRandomItem = () => {
    try {
      return (
        <section>
          <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
            {/* <header className="text-center">
              <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                New Collection
              </h2>

              <p className="max-w-md mx-auto mt-4 text-gray-500">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque
                praesentium cumque iure dicta incidunt est ipsam, officia dolor fugit
                natus?
              </p>
            </header> */}
            <h1 className="mt-10 text-5xl font-bold  md:mt-0 md:text-9xl lg:mt-0 lg:text-9xl ">
              {storeDetails != undefined
                ? storeDetails.welcomeMessageV2.header
                : "...."}
            </h1>
            <p className="text-md mb-2 mt-2">
              "Indulge in the beauty of nature with Flower Luxe Cebu's fresh and
              high-quality flower selections "{" "}
            </p>
            {/* <p>Use code: <span className='text-red-600 font-bold'>LUXEMOM2023</span></p> */}
            <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
              <li className=" hover:opacity-75">
                <a
                  href="javascript:void(0);"
                  onClick={() => eventDateNavigate(randomproducts[0])}
                  // disabled={randomproducts[0].status === false ? true:false}
                  className="group relative block"
                >
                  <img
                    src={randomproducts[0].imgUrl}
                    alt=""
                    className="aspect-square w-full rounded-lg object-cover transition duration-500 group-hover:opacity-90"
                  />

                  <div className="absolute inset-0 flex  flex-col items-start justify-end p-6">
                    <h3 className="text-xl font-medium text-white">
                      {moment(setHours(setMinutes(new Date(), 0), 9))
                        .add(1, "days")
                        .tz("Asia/Manila")
                        .format("MMMM DD, YYYY")
                        .toString()}
                    </h3>

                    <span className="mt-1.5 inline-block rounded-full bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                      Order me for tomorrow?
                    </span>
                  </div>
                </a>
              </li>

              <li className=" hover:opacity-75">
                <a
                  href="javascript:void(0);"
                  onClick={() => eventDateNavigate(randomproducts[1])}
                  href="javascript:void(0);"
                  // disabled={randomproducts[1].status === false ? true:false}
                  className="group relative block"
                >
                  <img
                    src={randomproducts[1].imgUrl}
                    alt=""
                    className="aspect-square w-full  rounded-lg object-cover transition duration-500 group-hover:opacity-90"
                  />
                  <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                    <h3 className="text-xl font-medium text-white">
                      {moment(setHours(setMinutes(new Date(), 0), 9))
                        .add(1, "days")
                        .tz("Asia/Manila")
                        .format("MMMM DD, YYYY")
                        .toString()}
                    </h3>
                    <div>
                      <a href="javascript:void(0);">
                        <span className="mt-1.5 inline-block rounded-full bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white lg:mt-8">
                          Order me for tomorrow?
                        </span>
                      </a>
                      {/* < a href={storeDetails != null  ? storeDetails.storeOptions.appLinks.android : ''}>
                    <span
                      className="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
                    >
                     Download Android
                    </span>
                    </a> */}
                    </div>
                  </div>
                </a>
              </li>

              <li className="hover:opacity-75 lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
                <a
                  href="javascript:void(0);"
                  onClick={() => eventDateNavigate(randomproducts[2])}
                  // disabled={randomproducts[2].status === false ? true:false}
                  className="group relative block"
                >
                  <img
                    // https://localflowershop.sgp1.digitaloceanspaces.com/product/1649906489955-flc.jpg
                    src={randomproducts[2].imgUrl}
                    alt=""
                    className="aspect-square w-full rounded-lg object-cover transition duration-500 group-hover:opacity-90"
                  />
                  <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                    <h3 className="text-xl font-medium text-white">
                      {moment(setHours(setMinutes(new Date(), 0), 9))
                        .add(1, "days")
                        .tz("Asia/Manila")
                        .format("MMMM DD, YYYY")
                        .toString()}
                    </h3>

                    <span className="mt-1.5 inline-block rounded-full bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white">
                      Order me for tomorrow?
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </section>
      );
    } catch (error) {
      console.log("displayRandomItem", error, randomproducts);
      return (
        <button
          className="ml-4  mt-10 h-10 w-auto rounded-full border-2  border-black text-xs lg:ml-20"
          onClick={() => window.location.reload(true)}
        >
          <div className="ml-2 mr-2"> Tap to display random flowers</div>
        </button>
      );
    }
  };
  return (
    <>
      <html className="" style={{ scrollBeha: "smooth" }}>
        <div
          className=" ml-auto mr-auto mt-20 bg-gray-200"
          style={{ height: 1.1, width: "90%" }}
        />
        {/* {displayRandomItem()} */}
        {mainContent()}
      </html>
    </>
  );
}

export default App;

{
  /* <div className={hasVoucher ? "sticky top-5 sm:top-10  z-10 flex-1 overflow-y-auto  lg:h-15 sm:h-10  transform transition duration-400" : "shadow transform transition duration-400 sticky top-0 z-10  lg:h-15 sm:h-10 xs:h-5  "} style={{ backgroundColor: 'white' }}>
        <div className="flex ... items-center   lg:justify-center   overflow-y-auto overflow-x-auto " >
          {categories.length ? renderCategories() : <CategoryPH />}
        </div>
      </div> */
}
